<template>
    <div class="grid">
        <div class="col-12 md:col-12">
            <div class="card p-fluid">
                <h5>{{ publicoAlvo.id ? 'Atualizar Público Alvo' : 'Novo Público Alvo' }}</h5>
                <div class="field col-9">
                    <label class="required">Título</label>
                    <InputText type="text" v-model="publicoAlvo.title" />
                </div>
                <div class="field col-9">
                    <label>Cod. Faturamento Sas</label>
                    <InputText type="text" v-model="publicoAlvo.codFaturamentoSas" />
                </div>
                <div class="field col-9">
                    <label class="required">Publico Sas</label>
                    <Dropdown v-model="publicoAlvo.codigoSas" :options="publicoSas" optionValue="CodPublicoAlvo" optionLabel="DescPublicoAlvo" showClear />
                </div>
                <div class="field-checkbox col-10">
                    <Checkbox id="binary" v-model="publicoAlvo.active" :binary="true" />
                    <label for="binary">Ativo</label>
                </div>
            </div>
            <Button label="Cancelar" @click.stop="cancelar" icon="pi pi-times-circle" class="mr-2 p-button-secondary"></Button>
            <Button label="Salvar" @click.stop="inserir" icon="pi pi-check-circle" :disabled="!publicoAlvo.title || !publicoAlvo.codigoSas"></Button>
        </div>
    </div>
</template>
<script>
import Services from './service';

export default {
    data() {
        return {
            publicoAlvo: {
                active: true,
                title: null,
                codigoSas: null,
                descricaoSas: null,
                codFaturamentoSas: null,
            },
            publicoSas: [],
        };
    },
    mounted() {
        if (this.$route.params.id) {
            Services.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.publicoAlvo = response.data;
                }
            });
        }
        this.obterPublicoSas();
    },

    methods: {
        inserir() {
            this.$store.dispatch('addRequest');

            if (this.publicoAlvo.id) {
                Services.atualizar(this.publicoAlvo.id, this.publicoAlvo).then((response) => {
                    this.respostaSalvar(response, true);
                });
            } else {
                Services.inserir(this.publicoAlvo).then((response) => {
                    this.respostaSalvar(response, false);
                });
            }
        },
        respostaSalvar(response, edicao) {
            if (response?.success) {
                this.$toast.add({
                    severity: 'success',
                    summary: 'Público Alvo',
                    detail: `Público Alvo ${edicao ? 'editado' : 'inserido'} com sucesso`,
                    life: 3000,
                });

                this.cancelar();
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: response.errors[0],
                    life: 10000,
                });
            }
            this.$store.dispatch('removeRequest');
        },
        obterPublicoSas() {
            this.$store.dispatch('addRequest');
            Services.obterPublicoSas().then((response) => {
                if (response?.success) {
                    this.publicoSas = response.data;
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: response.errors[0],
                        life: 10000,
                    });
                    let publicoDto = {
                        CodPublicoAlvo: this.publicoAlvo.codigoSas,
                        DescPublicoAlvo: this.publicoAlvo.descricaoSas,
                    };
                    this.publicoSas.push(publicoDto);
                }
                this.$store.dispatch('removeRequest');
            });
        },
        cancelar() {
            this.$router.push({
                name: `PublicoAlvo`,
            });
        },
    },
    watch: {
        'publicoAlvo.codigoSas'() {
            if (this.publicoAlvo.codigoSas > 0 && this.publicoSas.length > 0) {
                const result = this.publicoSas.filter((publico) => publico.CodPublicoAlvo == this.publicoAlvo.codigoSas);
                this.publicoAlvo.descricaoSas = result[0].DescPublicoAlvo;
                this.publicoAlvo.codigoSas = result[0].CodPublicoAlvo;
            }
        },
    },
};
</script>
